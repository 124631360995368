body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

*,
*::before,
*::after {
  box-sizing: content-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#tableWrapper g.recharts-layer.recharts-cartesian-axis-tick:first-child {
  display: none !important;
}

.MuiContainer-root {
  max-width: 1500px;
  @media screen and (max-width: 900px) {
    width: 98%;
    padding: 0px 8px;
  }
}

.MuiTabPanel-root {
  padding: 20px 0px;
}

.MuiAccordionDetails-root {
  @media screen and (max-width: 900px) {
    padding: 20px 0px;
  }
}

.MuiButton-root {
  border-radius: 4px;
  text-transform: uppercase;
}

/* Always show scrollbar */
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
